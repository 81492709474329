import styled from "styled-components";
import { V3StyledButton } from "../Button/V3Button.styles";

export const CCImgUploadWrapper = styled.div<{ disabled: boolean }>`
    position: relative;
    width: 100%;

    &:hover {
        ${V3StyledButton} {
            border: ${({ disabled, theme }) => disabled ? undefined : `1px solid ${theme.v3.colors.grey48}`};

            svg {
                path {
                    stroke: ${({ disabled, theme }) => disabled ? undefined : theme.v3.colors.grey48};
                }
            }
        }
    }

    ${V3StyledButton} {
        border-radius: 12px;
        padding: 10px, 24px, 10px, 24px; 
    }
`;

export const CCImgUploadInput = styled.input<{ disabled: boolean }>`
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:hover {
        cursor: ${({ disabled }) => !disabled ? "pointer" : undefined};
    }
`;

export const CustomMediaUploadingWrapper = styled.div`
    padding: 5px 10px;
    border-radius: 13px;
    background: white;
`;

export const MUWrapper = styled.div<{ $hasRemove: boolean }>`
    display: flex;
    align-items: center;

    > div {
        &:nth-child(1) {
            width: 100%;
            margin-right: ${({ $hasRemove }) => $hasRemove ? "20px" : undefined};
        }
    }
`;